<template>
  <div class="content__container">
    <p class="promo promo_blocks2">{{ "s4p17_text_1" | localize }}</p>
    <form @submit.prevent="submitHandler">
      <div class="form__group">
        <v-select
          v-model="delay"
          item-text="name"
          item-value="value"
          @blur="$v.delay.$touch()"
          @change="success = true"
          :class="{ invalid: $v.delay.$dirty && !$v.delay.required }"
          :items="getList('month_', 12)"
          :placeholder="'select' | localize"
          :menu-props="{ offsetY: true }"
          append-icon="icon-arrow-down"
          outlined
        ></v-select>
      </div>

      <div class="error__container">
        <p class="error" v-if="error">{{ error | localize }}</p>
      </div>

      <button type="button" class="button button__form" :class="{ button__form_disabled: !success }" @click="submitHandler">
        {{ "form_continue" | localize }}
      </button>
    </form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { commonMixin } from '@/mixins/commonMixin.js'
import { numberMixin } from '@/mixins/numberMixin.js'
import { saveMixin } from '@/mixins/saveMixin.js'

export default {
  name: 'About_delay',
  mixins: [ commonMixin, numberMixin, saveMixin],
  validations: { delay: { required } },
  data: () => ({ delay: null, error: null, success: false }),
  props: { 
    service: { type: String, required: true },
    nextlink: { type: String, required: true }
  },
  created () {
    const data = this.$cookies.get(this.service) || {}
    this.delay = data.delay || null
    this.success = !this.$v.delay.$invalid
  },
  methods: {
    submitHandler () {
      if (this.$v.delay.$invalid) {
        this.$v.delay.$touch()
        this.$scrollTo('.error__container', 300, { offset: -400 })
        this.success = false
        return
      }

      this.success = true
      const data = this.$cookies.get(this.service) || {}
      data.delay = this.delay
      this.$cookies.set(this.service, data)
      this.saveServiceInfo(this.service, this.nextlink, { delay: this.delay })
    }
  }
}
</script>